import React from "react";
import { Row, Col } from "react-bootstrap";
import Logo from "../../assets/images/logo.svg";
import wi from "../../assets/images/wi.webp";
import sm from "../../assets/images/sm.webp";

import "./style.scss";

const Navigation = () => {
  return (
    <>
      <div className="navigation">
        <div className="container">
          <div className="row">
            <div className="navLeft col-12 col-md-4">
              <div className="ms-0">
                <div className="leftTop">
                  <div className="position-sticky">
                    <img src={Logo} className="logo" alt="AKO MAG." />
                    <Row className="items g-0 mt-2 mt-md-3 ">
                      <Col className="itemLink">
                        <a
                          target={"_blank"}
                          href="https://ako-mag.jp/#sec-sightseeing"
                          rel="noreferrer"
                        >
                          いこう.
                        </a>
                      </Col>
                      <Col className="itemLink gray">かおう.</Col>
                      <Col className="itemLink">
                        <a
                          target={"_blank"}
                          href="https://ako-mag.jp/#sec-live"
                          rel="noreferrer"
                        >
                          すもう.
                        </a>
                      </Col>
                      <Col className="itemLink">
                        <a
                          target={"_blank"}
                          href="https://ako-mag.jp/letters/"
                          rel="noreferrer"
                        >
                          お便り.
                        </a>
                      </Col>
                      <Col className="itemLink">
                        <a
                          target={"_blank"}
                          href="https://ako-mag.jp/about/"
                          rel="noreferrer"
                        >
                          わたしたち.
                        </a>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <div className="navRight col-12 col-md-8">
              <div className="d-flex navRightWrap">
                <div className="me-2 textNav flex-shrink">
                  <div>
                    ｢赤穂｣の魅力が詰まった｢体験・アクティビティ｣や｢物産｣などを買って、あなたにとってイイ感じの赤穂を直接触れて感じてみてください♪
                  </div>
                </div>
                <div className="mt-3 mt-md-0 rightLinks flex-grow">
                  <div className="d-flex">
                    <div className="imageRight">
                      <img src={sm} />
                    </div>
                    <div className="imageRight">
                      <img src={wi} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navigation;
