export const TRANSLATIONS_JP = {
  search: "探す",
  booking: "予約",
  not_found: "現在商品を登録中です。またのご利用をお待ちしております。 😉",
  all_categories: "すべてのカテゴリ",
  accommodation: "宿泊施設",
  activity: "アクティビティ",
  restaurant: "飲食店",
  produce: "買い物",
  search_keyword: "キーワード",
  view_details: "詳細を見る",
  basic_info: "基本情報",
  map: "地図",
  home: "ホーム",
  list: "リスト",
  details: "詳細",
  name: "名前",
  address: "住所",
  phone: "電話",
  date: "日にち",
  fax: "ファックス",
  email: "メール",
  website: "ウェブサイト",
  from: "から",
  price: "価格",
  rate: "料金",
  price_range: "価格帯",
  keyword: "キーワード",
  available_products: "空室状況",
  available_products_activity: "予約状況",
  available_products_goods: "在庫状況",
  book_now: "予約",
  book_now_activ: "予約",
  book_now_goods: "購入",
  check_price: "空室状況を確認",
  check_price_activ: "予約状況を確認",
  check_price_goods: "在庫状況を確認",
  cart_empty: "カートは空です",
  first_name: "ファーストネーム",
  last_name: "苗字",
  re_email: "メールを再入力",
  mobile: "携帯電話/携帯電話",
  city: "郊外/都市",
  state: "州/国",
  country: "国",
  zip: "郵便番号",
  type_keywords: "キーワード",
  product: "製品",
  options: "オプション/コスト",
  totals: "合計",
  special_requests: "特別なお願い",
  quick_booking: "即時予約",
  request_book: "リクエスト予約",
  customer_detail: "顧客の詳細",
  load_more: "もっと読み込む",
  request_to_book: "予約のリクエスト",
  start_time: "始まる時間: {{ time }}",
  continue_payment: "予約・決済に進む",
  close: "閉じる",
  quantity: "数量",
  see_more: "続きを読む",
  modal_header: "予約リクエストを行う",
  modal_desc:
    "この予約はオペレーターに確認する必要があります。<br><br>24時間以内に、予約が承認されたかどうか、オンラインで安全に支払うことができるかどうかを確認するメールが届きます。",
  confirm: "Confirm",
  submit: "参加する",
  items: "アイテム",
  go_back: "戻る",
  booking_req_detail_wait: "予約リクエストの詳細-確認待ち",
  booking_req_instructions:
    "24時間以内に、予約リクエストがオペレーターによって受け入れられたかどうかを確認するメールが届きます。 予約リクエストが受理されるまで、支払いは行われません。",
  request_date: "リクエスト日（UTC）",
  booking_reference: "予約番号",
  booked_by: "によって予約された",
  supplier_information: "サプライヤー情報",
  total_gst: "合計（GSTを含む）",
  amount_paid: "支払額（GSTを含む）",
  amount_owing: "未払い額（GSTを含む）",
  adult: "大人",
  check_in: "チェックイン",
  check_out: "チェックアウト",
  not_found_page: "見つかりません",
  booking_date: "予約日",
  back_to_booking: "予約ページに戻る",
  sort_by: "並べ替え条件",
  name_ascending: "名前昇順",
  name_descending: "名前降順",
  rate_ascending: "価格の安い順",
  rate_descending: "価格の高い順",
  not_available: "満室",
  not_available_activ: "満席",
  not_available_goods: "在庫切れ",
  price_tax: "価格（税込)",
  date_placeholder: "年/月/日",
  reset_filter: 'フィルタをリセット',
  shopping: "買い物",
  item_added: "カートに追加しました",
  warning_dist: "先にカートに入っている商品の支払を完了させてください。",
  clear_date: "日付を削除",
  adults: "大人",
  seniors: "高齢者",
  children: "子供",
  duration: "間隔",
  adults_count: "大人: {{ adults }}",
  seniors_count: "高齢者 : {{ seniors }}",
  children_count: "子供 : {{ children }}",
  check_in_date: "チェックイン {{ date }}",
  check_out_date: "チェックアウト {{ date }}",
  estimated: "推定",
};
